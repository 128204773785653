import {
    SUPPLY_ORDERS_BATCH_DELETE_REQUEST,
    SUPPLY_ORDERS_BATCH_DELETE_SUCCESS,
    SUPPLY_ORDERS_BATCH_DELETE_FAILURE,
    SUPPLY_ORDERS_FETCH_MANY_REQUEST,
    SUPPLY_ORDERS_FETCH_MANY_SUCCESS,
    SUPPLY_ORDERS_FETCH_MANY_FAILURE,
    SUPPLY_ORDERS_FETCH_REQUEST,
    SUPPLY_ORDERS_FETCH_SUCCESS,
    SUPPLY_ORDERS_FETCH_FAILURE,
    SUPPLY_ORDERS_COUNT_REQUEST,
    SUPPLY_ORDERS_COUNT_SUCCESS,
    SUPPLY_ORDERS_COUNT_FAILURE,
    SUPPLY_ORDERS_SAVE_REQUEST,
    SUPPLY_ORDERS_SAVE_SUCCESS,
    SUPPLY_ORDERS_SAVE_FAILURE,
    EMPTY_SUPPLY_ORDERS,
    CLEAR_ERRORS
} from "./types";

export default {
    deleteMany({ commit }, { ids = [] }) {
        commit(SUPPLY_ORDERS_BATCH_DELETE_REQUEST);
        return window.axios
            .post("/supply-orders", { _method: "DELETE", ids })
            .then(response => {
                commit(SUPPLY_ORDERS_BATCH_DELETE_SUCCESS, response);
                return response;
            })
            .catch(error => {
                commit(SUPPLY_ORDERS_BATCH_DELETE_FAILURE, error);
                throw error;
            });
    },
    fetchMany({ commit }, { query = "", page = 1, status = null, extraParams = {} }) {
        commit(SUPPLY_ORDERS_FETCH_MANY_REQUEST);
        return window.axios
            .get("/supply-orders", { params: { query, page, status, ...extraParams } })
            .then(response => {
                commit(SUPPLY_ORDERS_FETCH_MANY_SUCCESS, response);
                return response;
            })
            .catch(error => {
                commit(SUPPLY_ORDERS_FETCH_MANY_FAILURE, error);
                throw error;
            });
    },
    fetch({ commit }, id) {
        commit(SUPPLY_ORDERS_FETCH_REQUEST);
        return window.axios
            .get(`/supply-orders/${id}`)
            .then(response => {
                commit(SUPPLY_ORDERS_FETCH_SUCCESS, response);
                return response;
            })
            .catch(error => {
                commit(SUPPLY_ORDERS_FETCH_FAILURE, error);
                throw error;
            });
    },
    save ({ commit }, user) {
        const path = user.id ? `/supply-orders/${user.id}` : "/supply-orders";
        const method = user.id ? "put" : "post";
        commit(SUPPLY_ORDERS_SAVE_REQUEST);
        return window.axios[method](path, user)
            .then(response => {
                commit(SUPPLY_ORDERS_SAVE_SUCCESS, response);
                return response;
            })
            .catch(error => {
                if (error.response.status === 422) {
                    commit(SUPPLY_ORDERS_SAVE_FAILURE, {
                        validationErrors: error.response.data
                    });
                } else {
                    commit(SUPPLY_ORDERS_SAVE_FAILURE, { error });
                }
                throw error;
            });
    },
    saveReturns({ commit }, user) {
        const path = user.id ? `/supply-returns/${user.id}` : "/supply-returns";
        const method = user.id ? "put" : "post";

        commit(SUPPLY_ORDERS_SAVE_REQUEST);
        return window.axios[method](path, user)
            .then(response => {
                commit(SUPPLY_ORDERS_SAVE_SUCCESS, response);
                return response;
            })
            .catch(error => {
                if (error.response.status === 422) {
                    commit(SUPPLY_ORDERS_SAVE_FAILURE, {
                        validationErrors: error.response.data
                    });
                } else {
                    commit(SUPPLY_ORDERS_SAVE_FAILURE, { error });
                }
                throw error;
            });
    },
    saveSupplyOrderItemLots ({ commit }, supplyOrderItem) {
        commit(SUPPLY_ORDERS_SAVE_REQUEST);
        return window.axios.post(`/supply-orders/lots/save/${supplyOrderItem.id}`, supplyOrderItem)
            .then(response => {
                commit(SUPPLY_ORDERS_SAVE_SUCCESS, response);
                return response;
            })
            .catch(error => {
                if (error.response.status === 422) {
                    commit(SUPPLY_ORDERS_SAVE_FAILURE, {
                        validationErrors: error.response.data
                    });
                } else {
                    commit(SUPPLY_ORDERS_SAVE_FAILURE, { error });
                }
                throw error;
            });
    },
    fetchNotifications({ commit }, { query = "", page = 1, extraParams = {} }) {
        commit(SUPPLY_ORDERS_FETCH_MANY_REQUEST);
        return window.axios
            .get("/supply-orders/notifications", { params: { query, page, ...extraParams } })
            .then(response => {
                commit(SUPPLY_ORDERS_FETCH_MANY_SUCCESS, response);
                return response;
            })
            .catch(error => {
                commit(SUPPLY_ORDERS_FETCH_MANY_FAILURE, error);
                throw error;
            });
    },
    dismissNotification ({ commit }, notificationId) {
        commit(SUPPLY_ORDERS_SAVE_REQUEST);
        return window.axios.post(`/supply-orders/notifications/dismiss/${notificationId}`)
            .then(response => {
                commit(SUPPLY_ORDERS_SAVE_SUCCESS, response);
                return response;
            })
            .catch(error => {
                if (error.response.status === 422) {
                    commit(SUPPLY_ORDERS_SAVE_FAILURE, {
                        validationErrors: error.response.data
                    });
                } else {
                    commit(SUPPLY_ORDERS_SAVE_FAILURE, { error });
                }
                throw error;
            });
    },
    getNotificationCount({ commit }) {
        commit(SUPPLY_ORDERS_COUNT_REQUEST);
        return window.axios
            .get(`/supply-orders/notifications/count`)
            .then(response => {
                commit(SUPPLY_ORDERS_COUNT_SUCCESS, response);
                return response;
            })
            .catch(error => {
                commit(SUPPLY_ORDERS_COUNT_FAILURE);
            })
    },
    empty({ commit }) {
        commit(EMPTY_SUPPLY_ORDERS);
    },
    clearErrors({ commit }) {
        commit(CLEAR_ERRORS);
    },
};
