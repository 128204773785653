import {
  CLEAR_ERRORS,
  EMPTY_TODOS,
  TODOS_BATCH_DELETE_FAILURE,
  TODOS_BATCH_DELETE_REQUEST,
  TODOS_BATCH_DELETE_SUCCESS,
  TODOS_FETCH_FAILURE,
  TODOS_FETCH_MANY_COMPLETED_FAILURE,
  TODOS_FETCH_MANY_COMPLETED_REQUEST,
  TODOS_FETCH_MANY_COMPLETED_SUCCESS,
  TODOS_FETCH_MANY_FAILURE,
  TODOS_FETCH_MANY_REQUEST,
  TODOS_FETCH_MANY_SUCCESS,
  TODOS_FETCH_REQUEST,
  TODOS_FETCH_SUCCESS,
  TODOS_SAVE_FAILURE,
  TODOS_SAVE_REQUEST,
  TODOS_SAVE_SUCCESS,
} from "./types";

export default {
  deleteMany({commit}, {ids = []}) {
    commit(TODOS_BATCH_DELETE_REQUEST);
    return window.axios
      .post("/todos", {_method: "DELETE", ids})
      .then(response => {
        commit(TODOS_BATCH_DELETE_SUCCESS, response);
        return response;
      })
      .catch(error => {
        commit(TODOS_BATCH_DELETE_FAILURE, error);
        throw error;
      });
  },
  fetchManyCompleted({commit}, {query = "", page = 1}) {
    commit(TODOS_FETCH_MANY_COMPLETED_REQUEST);
    return window.axios
      .get("/completed-todos", {params: {query, page}})
      .then(response => {
        commit(TODOS_FETCH_MANY_COMPLETED_SUCCESS, response);
        return response;
      })
      .catch(error => {
        commit(TODOS_FETCH_MANY_COMPLETED_FAILURE, error);
        throw error;
      });
  },
  fetchMany({commit}, {query = "", page = 1, extraParams = {}}) {
    commit(TODOS_FETCH_MANY_REQUEST);
    return window.axios
      .get("/todos", {params: {query, page, ...extraParams}})
      .then(response => {
        commit(TODOS_FETCH_MANY_SUCCESS, response);
        return response;
      })
      .catch(error => {
        commit(TODOS_FETCH_MANY_FAILURE, error);
        throw error;
      });
  },
  fetch({commit}, id) {
    commit(TODOS_FETCH_REQUEST);
    return window.axios
      .get(`/todos/${id}`)
      .then(response => {
        commit(TODOS_FETCH_SUCCESS, response);
        return response;
      })
      .catch(error => {
        commit(TODOS_FETCH_FAILURE, error);
        throw error;
      });
  },
  save({commit, dispatch}, todo) {
    const path = todo.id ? `/todos/${todo.id}` : "/todos";
    const method = todo.id ? "put" : "post";

    commit(TODOS_SAVE_REQUEST);
    return window.axios[method](path, todo)
      .then(response => {
        commit(TODOS_SAVE_SUCCESS, response);
        dispatch('fetchMany', {})
        return response;
      })
      .catch(error => {
        if (error.response.status === 422) {
          commit(TODOS_SAVE_FAILURE, {
            validationErrors: error.response.data
          });
        } else {
          commit(TODOS_SAVE_FAILURE, {error});
        }
        throw error;
      });
  },
  empty({commit}) {
    commit(EMPTY_TODOS);
  },
  clearErrors({commit}) {
    commit(CLEAR_ERRORS);
  },
  fetchManyLowStock({commit}, {query = "", page = 1, extraParams = {}}) {
    commit(TODOS_FETCH_MANY_REQUEST);
    return window.axios
      .get("/todos/stock", {
        params: {query, page, ...extraParams}
      })
      .then(response => {
        commit(TODOS_FETCH_MANY_SUCCESS, response);
        return response;
      })
      .catch(error => {
        commit(TODOS_FETCH_MANY_FAILURE, error);
        throw error;
      });
  },
};
