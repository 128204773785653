export const AUTH_LOGIN_REQUEST = 'AUTH_LOGIN_REQUEST'
export const AUTH_LOGIN_SUCCESS = 'AUTH_LOGIN_SUCCESS'
export const AUTH_LOGIN_FAILURE = 'AUTH_LOGIN_FAILURE'

export const AUTH_LOGOUT_REQUEST = 'AUTH_LOGOUT_REQUEST'
export const AUTH_LOGOUT_SUCCESS = 'AUTH_LOGOUT_SUCCESS'
export const AUTH_LOGOUT_FAILURE = 'AUTH_LOGOUT_FAILURE'

export const XSRF_TOKEN_REQUEST = 'XSRF_TOKEN_REQUEST'
export const XSRF_TOKEN_SUCCESS = 'XSRF_TOKEN_SUCCESS'
export const XSRF_TOKEN_FAILURE = 'XSRF_TOKEN_FAILURE'

