<template>
	<b-navbar fixed="top" variant="primary" class="d-print-none">
        <b-navbar-brand @click="$root.$emit('sidebar::toggle')" class="d-block d-md-none cursor-pointer">
        <b-icon class="text-white mr-2" font-scale="1.3" icon="layout-sidebar-inset"></b-icon>
        </b-navbar-brand>
		<b-navbar-brand to="/">
			<img alt="North East Carpets" height="42" src="../assets/logo-white2.svg" />
			<span class="sr-only">North East Carpets</span>
		</b-navbar-brand>
		<b-navbar-nav class="ml-auto">
			<b-nav-item-dropdown right variant="light">
				<template v-slot:button-content>
					<span class="text-light">{{ currentUser.name }}</span>
				</template>
				<b-dropdown-item :to="{ name: 'auth.logout' }">Sign Out</b-dropdown-item>
			</b-nav-item-dropdown>
		</b-navbar-nav>
	</b-navbar>
</template>

<script>
import currentUser from "../mixins/currentUser"

export default {
	mixins: [currentUser],
}
</script>

<style>
</style>
