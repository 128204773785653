import flatMap from "lodash/flatMap";
import pick from "lodash/pick";
import map from "lodash/map";
import omit from "lodash/omit";
import union from "lodash/union";

import {
  AUTH_LOGIN_FAILURE,
  AUTH_LOGIN_REQUEST,
  AUTH_LOGIN_SUCCESS,
  AUTH_LOGOUT_FAILURE,
  AUTH_LOGOUT_REQUEST,
  AUTH_LOGOUT_SUCCESS,
  XSRF_TOKEN_FAILURE,
  XSRF_TOKEN_REQUEST,
  XSRF_TOKEN_SUCCESS
} from "./types";
// import {flare} from "@flareapp/flare-client";

const pluckUserPermissions = response =>
  map(
    union(
      pick(response, "data.permissions").data.permissions,
      flatMap(pick(response, "data.roles").data.roles, "permissions")
    ),
    "name"
  );

// const {currentUser} = storeToRefs(useAuthentication());

export default {
  check({commit}) {
    commit(AUTH_LOGIN_REQUEST);
    return window.axios
      .get("/auth/check")
      .then(response => {
        // flare.addContextGroup("user", currentUser ? currentUser.value || {});
        commit(AUTH_LOGIN_SUCCESS, {
          perms: pluckUserPermissions(response),
          user: omit(response.data, ["permissions", "roles"])
        });
        return response;
      })
      .catch(error => {
        commit(AUTH_LOGIN_FAILURE, {error});
        throw error;
      });
  },
  login({commit}, payload) {
    commit(AUTH_LOGIN_REQUEST);
    return window.axios
      .post("/auth/login", payload)
      .then(response => {
        commit(AUTH_LOGIN_SUCCESS, {

          perms: pluckUserPermissions(response),
          user: omit(response.data, ["permissions", "roles"])
        });
        return response;
      })
      .catch(error => {
        if (error.response && error.response.status === 422) {
          return commit(AUTH_LOGIN_FAILURE, {
            errors: error.response.data.errors
          });
        }

        commit(AUTH_LOGIN_FAILURE, {error});
        throw error;
      });
  },
  logout({commit}) {
    commit(AUTH_LOGOUT_REQUEST);
    return window.axios
      .post("/logout")
      .then(response => {
        commit(AUTH_LOGOUT_SUCCESS);
        return response;
      })
      .catch(error => {
        commit(AUTH_LOGOUT_FAILURE, error);
        throw error;
      });
  },
  token({commit}) {
    commit(XSRF_TOKEN_REQUEST);
    return window.axios
      .get("/csrf-cookie")
      .then(response => {
        commit(XSRF_TOKEN_SUCCESS);
        return response;
      })
      .catch(error => {
        commit(XSRF_TOKEN_FAILURE, {error});
        throw error;
      });
  }
};
