<template>
  <div>
    <Header class="d-print-none"/>
    <Container>
      <template #sidebar>
        <Sidebar class="d-print-none"/>
      </template>
      <transition name="fade" mode="out-in">
        <router-view />
      </transition>
    </Container>
  </div>
</template>

<script>
import Container from '../components/Container'
import Sidebar from '../components/Sidebar'
import Header from '../components/Header'

export default {
  components: {
    Container,
    Sidebar,
    Header,
  }
}
</script>

<style lang="scss">
</style>
