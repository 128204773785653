<template>
  <b-container fluid>
    <b-row>
      <slot name="sidebar"></slot>
      <b-col class="content ml-sm-auto" cols="12" md="9" xl="10">
        <b-button id="toDoButton" v-b-modal.todo-modal class="todo-modal-button d-print-none">
          <b-icon icon="card-checklist"/>
        </b-button>
        <b-modal id="todo-modal" class="d-flex mx-auto d-print-none" hide-footer>
          <template #modal-title>
            To-Do List
          </template>
          <a class="btn btn-primary" @click="close()">View Completed Items
          </a>
          <ToDoList/>
        </b-modal>
        <slot></slot>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import currentUser from "../mixins/currentUser"
import momentMixin from "../mixins/momentMixin"
import ToDoList from "../components/ToDoList"

export default {
  mixins: [currentUser, momentMixin],
  components: {
    ToDoList
  },

  methods: {
    close() {
      this.$bvModal.hide('todo-modal');
      this.$router.push({name: 'dash.todos.completed'});
    }
  }
}
</script>

<style lang="scss">
.content {
  padding: 1.5 * 16px + 68px 0 0;
}

#toDoButton {
  z-index: 1;
}

.modal-header {
  justify-content: space-between;
}

.modal-dialog {
  max-width: 65%;
  margin-left: auto;
  margin-right: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: auto;
  display: flex;
}

.todo-modal-button {
  color: white;
  background: purple;
  opacity: 0.3;
  border: 1px solid purple;
  border-radius: 50px;
  position: fixed;
  bottom: 2%;
  right: 2%;
  font-size: 2rem;
  padding: 1rem 1.3rem;
  text-align: center;
  justify-content: center;
  align-items: center;
  transition: all ease-in-out 0.2s;
}

.todo-modal-button:hover {
  opacity: 1;
  background: purple;
  color: white;
}
</style>
