export const SUPPLY_ORDERS_BATCH_DELETE_REQUEST = 'SUPPLY_ORDERS_BATCH_DELETE_REQUEST'
export const SUPPLY_ORDERS_BATCH_DELETE_SUCCESS = 'SUPPLY_ORDERS_BATCH_DELETE_SUCCESS'
export const SUPPLY_ORDERS_BATCH_DELETE_FAILURE = 'SUPPLY_ORDERS_BATCH_DELETE_FAILURE'

export const SUPPLY_ORDERS_FETCH_MANY_REQUEST = 'SUPPLY_ORDERS_FETCH_MANY_REQUEST'
export const SUPPLY_ORDERS_FETCH_MANY_SUCCESS = 'SUPPLY_ORDERS_FETCH_MANY_SUCCESS'
export const SUPPLY_ORDERS_FETCH_MANY_FAILURE = 'SUPPLY_ORDERS_FETCH_MANY_FAILURE'

export const SUPPLY_ORDERS_FETCH_REQUEST = 'SUPPLY_ORDERS_FETCH_REQUEST'
export const SUPPLY_ORDERS_FETCH_SUCCESS = 'SUPPLY_ORDERS_FETCH_SUCCESS'
export const SUPPLY_ORDERS_FETCH_FAILURE = 'SUPPLY_ORDERS_FETCH_FAILURE'

export const SUPPLY_ORDERS_COUNT_REQUEST = 'SUPPLY_ORDERS_COUNT_REQUEST'
export const SUPPLY_ORDERS_COUNT_SUCCESS = 'SUPPLY_ORDERS_COUNT_SUCCESS'
export const SUPPLY_ORDERS_COUNT_FAILURE = 'SUPPLY_ORDERS_COUNT_FAILURE'

export const SUPPLY_ORDERS_SAVE_REQUEST = 'SUPPLY_ORDERS_SAVE_REQUEST'
export const SUPPLY_ORDERS_SAVE_SUCCESS = 'SUPPLY_ORDERS_SAVE_SUCCESS'
export const SUPPLY_ORDERS_SAVE_FAILURE = 'SUPPLY_ORDERS_SAVE_FAILURE'

export const EMPTY_SUPPLY_ORDERS = 'EMPTY_SUPPLY_ORDERS'
export const CLEAR_ERRORS = 'CLEAR_ERRORS'
