export const TODOS_BATCH_DELETE_REQUEST = 'TODOS_BATCH_DELETE_REQUEST'
export const TODOS_BATCH_DELETE_SUCCESS = 'TODOS_BATCH_DELETE_SUCCESS'
export const TODOS_BATCH_DELETE_FAILURE = 'TODOS_BATCH_DELETE_FAILURE'

export const TODOS_FETCH_MANY_REQUEST = 'TODOS_FETCH_MANY_REQUEST'
export const TODOS_FETCH_MANY_SUCCESS = 'TODOS_FETCH_MANY_SUCCESS'
export const TODOS_FETCH_MANY_FAILURE = 'TODOS_FETCH_MANY_FAILURE'

export const TODOS_FETCH_REQUEST = 'TODOS_FETCH_REQUEST'
export const TODOS_FETCH_SUCCESS = 'TODOS_FETCH_SUCCESS'
export const TODOS_FETCH_FAILURE = 'TODOS_FETCH_FAILURE'

export const TODOS_SAVE_REQUEST = 'TODOS_SAVE_REQUEST'
export const TODOS_SAVE_SUCCESS = 'TODOS_SAVE_SUCCESS'
export const TODOS_SAVE_FAILURE = 'TODOS_SAVE_FAILURE'

export const EMPTY_TODOS = 'EMPTY_TODOS'
export const CLEAR_ERRORS = 'CLEAR_ERRORS'

export const TODOS_FETCH_MANY_COMPLETED_REQUEST = 'TODOS_FETCH_MANY_COMPLETED_REQUEST'
export const TODOS_FETCH_MANY_COMPLETED_SUCCESS = 'TODOS_FETCH_MANY_COMPLETED_SUCCESS'
export const TODOS_FETCH_MANY_COMPLETED_FAILURE = 'TODOS_FETCH_MANY_COMPLETED_FAILURE'
