import {
  CLEAR_ERRORS,
  EMPTY_ORDERS,
  ORDER_NOTIFICATION_COUNT_FAILURE,
  ORDER_NOTIFICATION_COUNT_REQUEST,
  ORDER_NOTIFICATION_COUNT_SUCCESS,
  ORDERS_BATCH_DELETE_FAILURE,
  ORDERS_BATCH_DELETE_REQUEST,
  ORDERS_BATCH_DELETE_SUCCESS,
  ORDERS_CANCEL_FAILURE,
  ORDERS_CANCEL_REQUEST,
  ORDERS_CANCEL_SUCCESS,
  ORDERS_FETCH_FAILURE,
  ORDERS_FETCH_MANY_FAILURE,
  ORDERS_FETCH_MANY_REQUEST,
  ORDERS_FETCH_MANY_SUCCESS,
  ORDERS_FETCH_REQUEST,
  ORDERS_FETCH_SUCCESS,
  ORDERS_SAVE_FAILURE,
  ORDERS_SAVE_REQUEST,
  ORDERS_SAVE_SUCCESS
} from "./types";

export default {
  deleteMany({commit}, {ids = []}) {
    commit(ORDERS_BATCH_DELETE_REQUEST);
    return window.axios
      .post("/orders", {_method: "DELETE", ids})
      .then(response => {
        commit(ORDERS_BATCH_DELETE_SUCCESS, response);
        return response;
      })
      .catch(error => {
        commit(ORDERS_BATCH_DELETE_FAILURE, error);
        throw error;
      });
  },
  fetchMany({commit}, {query = "", page = 1, extraParams = {}}) {
    commit(ORDERS_FETCH_MANY_REQUEST);
    return window.axios
      .get("/orders", {params: {query, page, ...extraParams}})
      .then(response => {
        commit(ORDERS_FETCH_MANY_SUCCESS, response);
        return response;
      })
      .catch(error => {
        commit(ORDERS_FETCH_MANY_FAILURE, error);
        throw error;
      });
  },
  fetchManyAwaitingLots({commit}, {query = "", page = 1, extraParams = {}}) {
    commit(ORDERS_FETCH_MANY_REQUEST);
    return window.axios
      .get("/orders/awaiting-lots", {params: {query, page, ...extraParams}})
      .then(response => {
        commit(ORDERS_FETCH_MANY_SUCCESS, response);
        return response;
      })
      .catch(error => {
        commit(ORDERS_FETCH_MANY_FAILURE, error);
        throw error;
      });
  },
  fetch({commit}, id) {
    commit(ORDERS_FETCH_REQUEST);
    return window.axios
      .get(`/orders/${id}`)
      .then(response => {
        commit(ORDERS_FETCH_SUCCESS, response);
        return response;
      })
      .catch(error => {
        commit(ORDERS_FETCH_FAILURE, error);
        throw error;
      });
  },
  save({commit}, {id = null, data}) {
    const path = id ? `/orders/${id}?_method=PUT` : "/orders";
    commit(ORDERS_SAVE_REQUEST);
    return window.axios.post(path, data)
      .then(response => {
        commit(ORDERS_SAVE_SUCCESS, response);
        return response;
      })
      .catch(error => {
        if (error.response.status === 422) {
          commit(ORDERS_SAVE_FAILURE, {
            validationErrors: error.response.data
          });
        } else {
          commit(ORDERS_SAVE_FAILURE, {error});
        }
        throw error;
      });
  },
  empty({commit}) {
    commit(EMPTY_ORDERS);
  },
  clearErrors({commit}) {
    commit(CLEAR_ERRORS);
  },
  fetchManyEmptyPrices({commit}, {query = "", page = 1, extraParams = {}}) {
    commit(ORDERS_FETCH_MANY_REQUEST);
    return window.axios
      .get("/orders/empty-price", {params: {query, page, ...extraParams}})
      .then(response => {
        commit(ORDERS_FETCH_MANY_SUCCESS, response);
        return response;
      })
      .catch(error => {
        commit(ORDERS_FETCH_MANY_FAILURE, error);
        throw error;
      });
  },
  cancelOrder({commit}, orderId) {
    commit(ORDERS_CANCEL_REQUEST);
    return window.axios.post(`/orders/cancel/${orderId}`)
      .then(response => {
        commit(ORDERS_CANCEL_SUCCESS, response);
        return response;
      })
      .catch(error => {
        commit(ORDERS_CANCEL_FAILURE, error);
        throw error;
      })
  },

  // Note notifications
  fetchNoteNotifications({commit}, {query = "", page = 1, extraParams = {}}) {
    commit(ORDERS_FETCH_MANY_REQUEST);

    return window.axios
      .get("/orders/note-notifications", {params: {query, page, ...extraParams}})
      .then(response => {
        commit(ORDERS_FETCH_MANY_SUCCESS, response);
        return response;
      })
      .catch(error => {
        commit(ORDERS_FETCH_MANY_FAILURE, error);
        throw error;
      });
  },
  dismissNoteNotification({commit}, notificationId) {
    commit(ORDERS_SAVE_REQUEST);
    return window.axios.post(`/orders/note-notifications/dismiss/${notificationId}`)
      .then(response => {
        commit(ORDERS_SAVE_SUCCESS, response);
        return response;
      })
      .catch(error => {
        if (error.response.status === 422) {
          commit(ORDERS_SAVE_FAILURE, {
            validationErrors: error.response.data
          });
        } else {
          commit(ORDERS_SAVE_FAILURE, {error});
        }
        throw error;
      });
  },
  getNoteNotificationCount({commit}) {
    commit(ORDER_NOTIFICATION_COUNT_REQUEST);
    return window.axios
      .get(`/orders/note-notifications/count`)
      .then(response => {
        commit(ORDER_NOTIFICATION_COUNT_SUCCESS, response);
        return response;
      })
      .catch(error => {
        commit(ORDER_NOTIFICATION_COUNT_FAILURE);
      })
  },
};
