<template>
	<b-col :class="{'d-flex': showSidebar, 'd-none': !showSidebar}" class="bg-light d-none d-md-block sidebar" cols="12" md="3" xl="2">
		<div class="mh-100 w-100 sidebar-scroll">
			<b-nav class="mb-4" vertical v-if="hasAnyPermission(['view-home', 'view-overview', 'view-dashboard'])">
				<b-nav-text class="nav-header">Home</b-nav-text>
				<b-nav-item class="text-dark" v-if="hasAnyPermission(['view-overview'])" to="/" :active="routeIdentifierActive('overview')"><b-icon class="mr-2" font-scale="1.4" icon="globe" /> Overview</b-nav-item>
				<b-nav-item class="text-dark" v-if="hasAnyPermission(['view-dashboard'])" to="/dashboard" :active="routeIdentifierActive('dashboard')"><b-icon class="mr-2" font-scale="1.4" icon="house" /> Dashboard</b-nav-item>
			</b-nav>
			<b-nav class="mb-4" vertical v-if="hasAnyPermission(['view-customers'])">
				<b-nav-text class="nav-header">Customers</b-nav-text>
				<b-nav-item class="text-dark" v-if="hasAnyPermission(['view-customers'])" to="/customers" :active="routeIdentifierActive('customers')"><b-icon class="mr-2" font-scale="1.4" icon="person" /> Customers</b-nav-item>
			</b-nav>
			<b-nav class="mb-4" vertical v-if="hasAnyPermission(['view-orders', 'view-quotes'])">
				<b-nav-text class="nav-header">Orders & Quotes </b-nav-text>
				<b-nav-item class="text-dark" v-if="hasAnyPermission(['view-quotes'])" to="/quotes" :active="routeIdentifierActive('quotes')"><b-icon class="mr-2" font-scale="1.4" icon="file-check" /> Quotes</b-nav-item>
				<b-nav-item class="text-dark" v-if="hasAnyPermission(['view-orders'])" to="/orders" :active="routeIdentifierActive('orders')"><b-icon class="mr-2" font-scale="1.4" icon="cart-4" /> Orders</b-nav-item>
				<b-nav-item class="text-dark" v-if="hasAnyPermission(['view-orders'])" to="/orders/awaiting-lots" :active="routeIdentifierActive('orders-awaiting-lots')"><b-icon class="mr-2" font-scale="1.4" icon="exclamation-circle" /> Orders Awaiting Lots</b-nav-item>
				<b-nav-item class="text-dark" v-if="hasAnyPermission(['view-orders'])" to="/orders/note-notifications" :active="routeIdentifierActive('order-note-notifications')"><b-icon class="mr-2" font-scale="1.4" icon="exclamation-circle" /> <b-badge variant="warning" v-if="orderNoteNotificationCount">{{ orderNoteNotificationCount }}</b-badge>  Order Note Notifications</b-nav-item>
			</b-nav>
			<b-nav class="mb-4" vertical v-if="hasAnyPermission(['view-payments'])">
				<b-nav-text class="nav-header">Payments</b-nav-text>
				<b-nav-item class="text-dark" v-if="hasAnyPermission(['view-payments'])" to="/payments" :active="routeIdentifierActive('payments')"><b-icon class="mr-2" font-scale="1.4" icon="cash" /> Payments</b-nav-item>
			</b-nav>
			<b-nav class="mb-4" vertical v-if="hasAnyPermission(['view-fittings'])">
				<b-nav-text class="nav-header">Fittings</b-nav-text>
				<b-nav-item class="text-dark" v-if="hasAnyPermission(['view-fittings'])" to="/fittings" :active="routeIdentifierActive('fittings')"><b-icon class="mr-2" font-scale="1.4" icon="book" /> Fittings</b-nav-item>
				<b-nav-item class="text-dark" v-if="hasAnyPermission(['view-fittings'])" to="/fittings/notifications" :active="routeIdentifierActive('fitting-notifications')"><b-icon class="mr-2" font-scale="1.4" icon="book" /> <b-badge variant="warning" v-if="fittingNotificationCount">{{ fittingNotificationCount }}</b-badge>  Fitting Notifications</b-nav-item>
			</b-nav>
            <b-nav class="mb-4" vertical v-if="hasAnyPermission(['view-calendar'])">
				<b-nav-text class="nav-header">Calendar</b-nav-text>
				<b-nav-item class="text-dark" v-if="hasAnyPermission(['view-calendar'])" to="/calendars" :active="routeIdentifierActive('calendars')"><b-icon class="mr-2" font-scale="1.4" icon="calendar2-date" /> Calendar</b-nav-item>
			</b-nav>
            <b-nav class="mb-4" vertical v-if="hasAnyPermission(['view-products', 'view-categories'])">
                <b-nav-text class="nav-header">Products</b-nav-text>
				<b-nav-item class="text-dark" v-if="hasAnyPermission(['view-products'])" to="/products" :active="routeIdentifierActive('products')"><b-icon class="mr-2" font-scale="1.4" icon="box-seam" /> Products</b-nav-item>
				<b-nav-item class="text-dark" v-if="hasAnyPermission(['view-categories'])" to="/categories" :active="routeIdentifierActive('categories')"><b-icon class="mr-2" font-scale="1.4" icon="collection" /> Categories</b-nav-item>
            </b-nav>
            <b-nav class="mb-4" vertical v-if="hasAnyPermission(['view-cash-up'])">
                <b-nav-text class="nav-header">Cash Up</b-nav-text>
				<b-nav-item class="text-dark" v-if="hasAnyPermission(['view-cash-up'])" to="/cash-up" :active="routeIdentifierActive('cash-up')"><b-icon class="mr-2" font-scale="1.4" icon="cash-stack" /> Cash Up</b-nav-item>
            </b-nav>
			<b-nav class="mb-4" vertical v-if="hasAnyPermission(['view-supply-order', 'view-suppliers'])">
				<b-nav-text class="nav-header">Suppliers</b-nav-text>
				<b-nav-item class="text-dark" v-if="hasAnyPermission(['view-supply-order'])" to="/supply-orders/notifications" :active="routeIdentifierActive('supply-order-notifications')"><b-icon class="mr-2" font-scale="1.4" icon="bell" /> <b-badge variant="warning" v-if="supplyOrderNotificationCount">{{ supplyOrderNotificationCount }}</b-badge> Supply Order Notifications</b-nav-item>
				<b-nav-item class="text-dark" v-if="hasAnyPermission(['view-supply-order'])" to="/supply-orders" :active="routeIdentifierActive('supply-orders')"><b-icon class="mr-2" font-scale="1.4" icon="box" /> Supply Orders</b-nav-item>
				<b-nav-item class="text-dark" v-if="hasAnyPermission(['view-suppliers'])" to="/suppliers" :active="routeIdentifierActive('suppliers')"><b-icon class="mr-2" font-scale="1.4" icon="shop" /> Suppliers</b-nav-item>
				<b-nav-item class="text-dark" v-if="hasAnyPermission(['view-supply-order'])" to="/supply-orders/pending-returns" :active="routeIdentifierActive('pending-returns')"><b-icon class="mr-2" font-scale="1.4" icon="shop" /> Pending Returns</b-nav-item>
			</b-nav>
			<b-nav class="mb-4" vertical v-if="hasAnyPermission(['view-reports'])">
				<b-nav-text class="nav-header">Reporting</b-nav-text>
				<b-nav-item class="text-dark" v-if="hasAnyPermission(['view-reports'])" to="/reports" :active="routeIdentifierActive('reports')"><b-icon class="mr-2" font-scale="1.4" icon="graph-up" /> Reports</b-nav-item>
			</b-nav>
            <b-nav class="mb-4" vertical v-if="hasAnyPermission(['view-users'])">
				<b-nav-text class="nav-header">Users</b-nav-text>
				<b-nav-item class="text-dark" v-if="hasAnyPermission(['view-users'])" to="/users" :active="routeIdentifierActive('users')"><b-icon class="mr-2" font-scale="1.4" icon="person-circle" /> Users</b-nav-item>
            </b-nav>
		</div>
	</b-col>
</template>

<script>
import { mapGetters } from "vuex"

export default {
  data () {
    return { showSidebar: false }
  },
	computed: {
		...mapGetters("authentication", ["hasAnyPermission"]),
		...mapGetters("supply-orders", {
			"supplyOrderNotificationCount": "notificationCount"
		}),
		...mapGetters("fittings", {
			"fittingNotificationCount": "notificationCount"
		}),
		...mapGetters("orders", {
			"orderNoteNotificationCount": "notificationNoteCount"
		}),
	},
	methods: {
        routeIdentifierActive(routeIdentifier) {
            if(!this.$route.meta.hasOwnProperty('route_identifier')) return false
            return (routeIdentifier == this.$route.meta.route_identifier)
        }
	},
  mounted () {
    this.$root.$on('sidebar::toggle', () => {
      this.showSidebar = !this.showSidebar
    })
    this.$root.$on('sidebar::set', (value) => {
      this.showSidebar = value
    })
  },
}
</script>

<style lang="scss" scoped>
.sidebar {
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	padding: 68px 0 0;
	z-index: 100;
	border-right: 1px solid darken($light, 10%);

	.sidebar-scroll {
		overflow-y: scroll;
	}

	ul.nav {
		li.nav-header {
			color: lighten($dark, 20%);
			font-size: 0.8rem;
			font-weight: bold;
			padding: 0.5rem 1rem;
			text-transform: uppercase;
		}

		li.nav-item {
			text-overflow: ellipsis;
			width: 100%;

			a {
				color: $dark;
				text-overflow: ellipsis;
				white-space: nowrap;
				width: 100%;

				svg {
					color: lighten($dark, 10%);
					margin-top: -0.2em;
				}

				&:not(.active):hover {
					background: darken($light, 5%);
				}

				&.active {
					background: $primary;
					color: $light;

					svg {
						color: $light;
					}
				}
			}
		}
	}
}
</style>
