import Vue from "vue";
import VueRouter from "vue-router";
import includes from "lodash/includes";
import Container from "../views/Dashboard";
import permissions from "../permissions.json";
import store from "../store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: Container,
    children: [
      // Overview
      {
        path: "",
        name: "dash.overview",
        meta: {auth: true, can: permissions.CAN_VIEW_OVERVIEW},
        component: () =>
          import(
            /* webpackChunkName: "overview" */ "../views/Overview/Wrapper.vue"
            ),
        children: [
          {
            path: "",
            component: () =>
              import(
                /* webpackChunkName: "overview" */ "../views/Overview/Overview.vue"
                ),
            meta: {
              auth: true,
              can: permissions.CAN_VIEW_OVERVIEW,
              title: "Overview",
              route_identifier: 'overview'
            }
          }
        ]
      },
      // Dashboard
      {
        path: "dashboard",
        name: "dash.index",
        meta: {auth: true, can: permissions.CAN_VIEW_DASHBOARD},
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ "../views/Dashboard/Wrapper.vue"
            ),
        children: [
          {
            path: "",
            component: () =>
              import(
                /* webpackChunkName: "dashboard" */ "../views/Dashboard/Dashboard.vue"
                ),
            meta: {
              auth: true,
              can: permissions.CAN_VIEW_DASHBOARD,
              title: "Dashboard",
              route_identifier: 'dashboard'
            }
          }
        ]
      },
      // Customers
      {
        path: "customers",
        component: () =>
          import(
            /* webpackChunkName: "customers" */ "../views/Customers/Wrapper.vue"
            ),
        children: [
          {
            path: "",
            name: "dash.customers.list",
            meta: {
              auth: true,
              can: permissions.CAN_VIEW_CUSTOMERS,
              title: "Customers",
              route_identifier: 'customers'
            },
            component: () =>
              import(
                /* webpackChunkName: "customers" */ "../views/Customers/List.vue"
                )
          },
          {
            path: "create",
            name: "dash.customers.create",
            meta: {
              auth: true,
              can: permissions.CAN_MANAGE_CUSTOMERS,
              title: "Create Customer",
              route_identifier: 'customers'
            },
            component: () =>
              import(
                /* webpackChunkName: "customers" */ "../views/Customers/Upsert.vue"
                )
          },
          {
            path: ":id",
            name: "dash.customers.update",
            meta: {
              auth: true,
              can: permissions.CAN_MANAGE_CUSTOMERS,
              title: "Update Customer",
              route_identifier: 'customers'
            },
            component: () =>
              import(
                /* webpackChunkName: "customers" */ "../views/Customers/Upsert.vue"
                )
          },
          {
            path: "view/:id",
            name: "dash.customers.view",
            meta: {
              auth: true,
              can: permissions.CAN_MANAGE_CUSTOMERS,
              title: "View Customer",
              route_identifier: 'customers'
            },
            component: () =>
              import(
                /* webpackChunkName: "customers" */ "../views/Customers/View.vue"
                )
          }
        ]
      },
      // Users
      {
        path: "users",
        component: () =>
          import(
            /* webpackChunkName: "users" */ "../views/Users/Wrapper.vue"
            ),
        children: [
          {
            path: "",
            name: "dash.users.list",
            meta: {
              auth: true,
              can: permissions.CAN_VIEW_USERS,
              title: "Users",
              route_identifier: 'users'
            },
            component: () =>
              import(
                /* webpackChunkName: "users" */ "../views/Users/List.vue"
                )
          },
          {
            path: "create",
            name: "dash.users.create",
            meta: {
              auth: true,
              can: permissions.CAN_MANAGE_USERS,
              title: "Create User",
              route_identifier: 'users'
            },
            component: () =>
              import(
                /* webpackChunkName: "users" */ "../views/Users/Upsert.vue"
                )
          },
          {
            path: ":id",
            name: "dash.users.update",
            meta: {
              auth: true,
              can: permissions.CAN_MANAGE_USERS,
              title: "Update User",
              route_identifier: 'users'
            },
            component: () =>
              import(
                /* webpackChunkName: "users" */ "../views/Users/Upsert.vue"
                )
          },
          {
            path: "view/:id",
            name: "dash.users.view",
            meta: {
              auth: true,
              can: permissions.CAN_MANAGE_USERS,
              title: "View User",
              route_identifier: 'users'
            },
            component: () =>
              import(
                /* webpackChunkName: "users" */ "../views/Users/View.vue"
                )
          }
        ]
      },
      // Suppliers
      {
        path: "suppliers",
        component: () =>
          import(
            /* webpackChunkName: "suppliers" */ "../views/Suppliers/Wrapper.vue"
            ),
        children: [
          {
            path: "",
            name: "dash.suppliers.list",
            meta: {
              auth: true,
              can: permissions.CAN_VIEW_SUPPLIERS,
              title: "Suppliers",
              route_identifier: 'suppliers'
            },
            component: () =>
              import(
                /* webpackChunkName: "suppliers" */ "../views/Suppliers/List.vue"
                )
          },
          {
            path: "create",
            name: "dash.suppliers.create",
            meta: {
              auth: true,
              can: permissions.CAN_MANAGE_SUPPLIERS,
              title: "Create Supplier",
              route_identifier: 'suppliers'
            },
            component: () =>
              import(
                /* webpackChunkName: "suppliers" */ "../views/Suppliers/Upsert.vue"
                )
          },
          {
            path: ":id",
            name: "dash.suppliers.update",
            meta: {
              auth: true,
              can: permissions.CAN_MANAGE_SUPPLIERS,
              title: "Update Supplier",
              route_identifier: 'suppliers'
            },
            component: () =>
              import(
                /* webpackChunkName: "suppliers" */ "../views/Suppliers/Upsert.vue"
                )
          },
          {
            path: "view/:id",
            name: "dash.suppliers.view",
            meta: {
              auth: true,
              can: permissions.CAN_MANAGE_SUPPLIERS,
              title: "View Supplier",
              route_identifier: 'suppliers'
            },
            component: () =>
              import(
                /* webpackChunkName: "suppliers" */ "../views/Suppliers/View.vue"
                )
          }
        ]
      },
      // Supply Order
      {
        path: "supply-orders",
        component: () =>
          import(
            /* webpackChunkName: "supply-orders" */ "../views/SupplyOrders/Wrapper.vue"
            ),
        children: [
          {
            path: "",
            name: "dash.supply_orders.list",
            meta: {
              auth: true,
              can: permissions.CAN_VIEW_PRODUCTS,
              title: "Supply Order List",
              route_identifier: 'supply-orders'
            },
            component: () =>
              import(
                /* webpackChunkName: "supply-orders" */ "../views/SupplyOrders/List.vue"
                )
          },
          {
            path: "notifications",
            name: "dash.supply_orders.notifications",
            meta: {
              auth: true,
              can: permissions.CAN_VIEW_PRODUCTS,
              title: "Supply Order Notifications",
              route_identifier: 'supply-order-notifications'
            },
            component: () =>
              import(
                /* webpackChunkName: "supply-orders" */ "../views/SupplyOrders/Notifications.vue"
                )
          },
          {
            path: "create",
            name: "dash.supply_orders.create",
            meta: {
              auth: true,
              can: permissions.CAN_MANAGE_SUPPLIERS,
              title: "Create Supply Order",
              route_identifier: 'supply-orders'
            },
            component: () =>
              import(
                /* webpackChunkName: "supply-orders" */ "../views/SupplyOrders/Upsert.vue"
                )
          },
          {
            path: "view/:id",
            name: "dash.supply_orders.view",
            meta: {
              auth: true,
              can: permissions.CAN_MANAGE_SUPPLIERS,
              title: "View Supply Order",
              route_identifier: 'supply-orders'
            },
            component: () =>
              import("../views/SupplyOrders/View.vue")
          },
          {
            path: "pending-returns",
            name: "dash.supply_orders.pending-returns",
            meta: {
              auth: true,
              can: permissions.CAN_MANAGE_PRODUCTS,
              title: "Supply Returns",
              route_identifier: 'pending-returns'
            },
            component: () =>
              import("../views/SupplyOrders/PendingReturnsList.vue")
          },
          {
            path: ":id",
            name: "dash.supply_orders.update",
            meta: {
              auth: true,
              can: permissions.CAN_MANAGE_PRODUCTS,
              title: "Update Supply Order",
              route_identifier: 'supply-orders'
            },
            component: () =>
              import("../views/SupplyOrders/Upsert.vue")
          }
        ]
      },
      // Products
      {
        path: "products",
        component: () =>
          import(
            /* webpackChunkName: "products" */ "../views/Products/Wrapper.vue"
            ),
        children: [
          {
            path: "stock",
            name: "dash.products.stock",
            meta: {
              auth: true,
              can: permissions.CAN_VIEW_STOCK,
              title: "Stock Orders",
              route_identifier: 'stock'
            },
            component: () =>
              import(
                /* webpackChunkName: "products" */ "../views/Products/Stock.vue"
                )
          },
          {
            path: "",
            name: "dash.products.list",
            meta: {
              auth: true,
              can: permissions.CAN_VIEW_PRODUCTS,
              title: "Products",
              route_identifier: 'products'
            },
            component: () =>
              import(
                /* webpackChunkName: "products" */ "../views/Products/List.vue"
                )
          },
          {
            path: "create",
            name: "dash.products.create",
            meta: {
              auth: true,
              can: permissions.CAN_MANAGE_PRODUCTS,
              title: "Add Products",
              route_identifier: 'products'
            },
            component: () =>
              import(
                /* webpackChunkName: "products" */ "../views/Products/Upsert.vue"
                )
          },
          {
            path: "view/:id",
            name: "dash.products.view",
            meta: {
              auth: true,
              can: permissions.CAN_MANAGE_PRODUCTS,
              title: "View Products",
              route_identifier: 'products'
            },
            component: () =>
              import(
                /* webpackChunkName: "products" */ "../views/Products/View.vue"
                )
          },
          {
            path: ":id",
            name: "dash.products.update",
            meta: {
              auth: true,
              can: permissions.CAN_MANAGE_PRODUCTS,
              title: "Update Products",
              route_identifier: 'products'
            },
            component: () =>
              import(
                /* webpackChunkName: "products" */ "../views/Products/Upsert.vue"
                )
          }
        ]
      },
      // Categories
      {
        path: "categories",
        component: () =>
          import(
            /* webpackChunkName: "categories" */ "../views/Categories/Wrapper.vue"
            ),
        children: [
          {
            path: "",
            name: "dash.categories.list",
            meta: {
              auth: true,
              can: permissions.CAN_VIEW_CATEGORIES,
              title: "Categories",
              route_identifier: 'categories'
            },
            component: () =>
              import(
                /* webpackChunkName: "categories" */ "../views/Categories/List.vue"
                )
          },
          {
            path: "create",
            name: "dash.categories.create",
            meta: {
              auth: true,
              can: permissions.CAN_MANAGE_CATEGORIES,
              title: "Add Categories",
              route_identifier: 'categories'
            },
            component: () =>
              import(
                /* webpackChunkName: "categories" */ "../views/Categories/Upsert.vue"
                )
          },
          {
            path: "view/:id",
            name: "dash.categories.view",
            meta: {
              auth: true,
              can: permissions.CAN_VIEW_CATEGORIES,
              title: "View Categories",
              route_identifier: 'categories'
            },
            component: () =>
              import(
                /* webpackChunkName: "categories" */ "../views/Categories/View.vue"
                )
          },
          {
            path: ":id",
            name: "dash.categories.update",
            meta: {
              auth: true,
              can: permissions.CAN_MANAGE_CATEGORIES,
              title: "Update Categories",
              route_identifier: 'categories'
            },
            component: () =>
              import(
                /* webpackChunkName: "categories" */ "../views/Categories/Upsert.vue"
                )
          }
        ]
      },
      // Quotes
      {
        path: "quotes",
        component: () =>
          import(
            /* webpackChunkName: "quotes" */ "../views/Quotes/Wrapper.vue"
            ),
        children: [
          {
            path: "",
            name: "dash.quotes.list",
            meta: {
              auth: true,
              can: permissions.CAN_VIEW_QUOTES,
              title: "Quotes",
              route_identifier: 'quotes'
            },
            component: () =>
              import(
                /* webpackChunkName: "quotes" */ "../views/Quotes/List.vue"
                )
          },
          {
            path: "create",
            name: "dash.quotes.create",
            meta: {
              auth: true,
              can: permissions.CAN_MANAGE_QUOTES,
              title: "Add Quote",
              route_identifier: 'quotes'
            },
            component: () =>
              import(
                /* webpackChunkName: "quotes" */ "../views/Quotes/Upsert.vue"
                )
          },
          {
            path: "view/:id",
            name: "dash.quotes.view",
            meta: {
              auth: true,
              can: permissions.CAN_MANAGE_QUOTES,
              title: "View Quote",
              route_identifier: 'quotes'
            },
            component: () =>
              import(
                /* webpackChunkName: "quotes" */ "../views/Quotes/View.vue"
                )
          },
          {
            path: "ageing",
            name: "dash.quotes.ageing",
            meta: {
              auth: true,
              can: permissions.CAN_VIEW_QUOTES,
              title: "Ageing Quotes",
              route_identifier: 'quotes'
            },
            component: () =>
              import(
                /* webpackChunkName: "quotes" */ "../views/Quotes/Ageing.vue"
                )
          },
          {
            path: ":id",
            name: "dash.quotes.update",
            meta: {
              auth: true,
              can: permissions.CAN_MANAGE_QUOTES,
              title: "Update Quote",
              route_identifier: 'quotes'
            },
            component: () =>
              import(
                /* webpackChunkName: "quotes" */ "../views/Quotes/Upsert.vue"
                )
          }
        ]
      },
      // Orders
      {
        path: "orders",
        component: () =>
          import(
            /* webpackChunkName: "orders" */ "../views/Orders/Wrapper.vue"
            ),
        children: [
          {
            path: "",
            name: "dash.orders.list",
            meta: {
              auth: true,
              can: permissions.CAN_VIEW_ORDERS,
              title: "Orders",
              route_identifier: 'orders'
            },
            component: () =>
              import(
                /* webpackChunkName: "orders" */ "../views/Orders/List.vue"
                )
          },
          {
            path: "awaiting-lots",
            name: "dash.orders.awaiting-lots",
            meta: {
              auth: true,
              can: permissions.CAN_VIEW_ORDERS,
              title: "Orders",
              route_identifier: 'orders-awaiting-lots'
            },
            component: () =>
              import(
                /* webpackChunkName: "orders" */ "../views/Orders/AwaitingLots.vue"
                )
          },
          {
            path: "create",
            name: "dash.orders.create",
            meta: {
              auth: true,
              can: permissions.CAN_MANAGE_ORDERS,
              title: "Add Orders",
              route_identifier: 'orders'
            },
            component: () =>
              import(
                /* webpackChunkName: "orders" */ "../views/Orders/Upsert.vue"
                )
          },
          {
            path: "empty-price",
            name: "dash.orders.empty-price",
            meta: {
              auth: true,
              can: permissions.CAN_MANAGE_ORDERS,
              title: "Empty Price",
              route_identifier: 'orders'
            },
            component: () =>
              import(
                /* webpackChunkName: "orders" */ "../views/Orders/EmptyPrice.vue"
                )
          },
          {
            path: "note-notifications",
            name: "dash.orders.note-notifications",
            meta: {
              auth: true,
              can: permissions.CAN_MANAGE_ORDERS,
              title: "Order Note Notifications",
              route_identifier: 'order-note-notifications'
            },
            component: () =>
              import(
                /* webpackChunkName: "fittings" */ "../views/Orders/OrderNoteNotifications.vue"
                )
          },
          {
            path: ":id",
            name: "dash.orders.update",
            meta: {
              auth: true,
              can: permissions.CAN_MANAGE_ORDERS,
              title: "Update Orders",
              route_identifier: 'orders'
            },
            component: () =>
              import(
                /* webpackChunkName: "orders" */ "../views/Orders/Upsert.vue"
                )
          },
          {
            path: "view/:id",
            name: "dash.orders.view",
            meta: {
              auth: true,
              can: permissions.CAN_MANAGE_ORDERS,
              title: "View Order",
              route_identifier: 'orders'
            },
            component: () =>
              import(
                /* webpackChunkName: "orders" */ "../views/Orders/View.vue"
                )
          }
        ]
      },
      // Cash Up
      {
        path: 'cash-up',
        component: () => import('../views/CashUps/Wrapper.vue'),
        children: [
          {
            path: '/',
            name: 'dash.cash-ups.view',
            meta: {
              auth: true,
              can: permissions.CAN_VIEW_CASH_UP,
              title: 'Cash-Up Facility',
              route_identifier: 'cash-up'
            },
            component: () => import('../views/CashUps/CashUp.vue')
          }
        ],
      },
      // Fittings
      {
        path: "fittings",
        component: () =>
          import(
            /* webpackChunkName: "fittings" */ "../views/Fittings/Wrapper.vue"
            ),
        children: [
          {
            path: "",
            name: "dash.fittings.list",
            meta: {
              auth: true,
              can: permissions.CAN_VIEW_FITTINGS,
              title: "Fittings",
              route_identifier: 'fittings'
            },
            component: () =>
              import(
                /* webpackChunkName: "fittings" */ "../views/Fittings/List.vue"
                )
          },
          {
            path: "view/:id",
            name: "dash.fittings.view",
            meta: {
              auth: true,
              can: permissions.CAN_MANAGE_FITTINGS,
              title: "View Fittings",
              route_identifier: 'fittings'
            },
            component: () =>
              import(
                /* webpackChunkName: "fittings" */ "../views/Fittings/View.vue"
                )
          },
          {
            path: "notifications",
            name: "dash.fittings.notifications",
            meta: {
              auth: true,
              can: permissions.CAN_MANAGE_FITTINGS,
              title: "Fitting Notifications",
              route_identifier: 'fitting-notifications'
            },
            component: () =>
              import(
                /* webpackChunkName: "fittings" */ "../views/Fittings/FittingNotifications.vue"
                )
          }
        ]
      },
      // Payments
      {
        path: "payments",
        component: () =>
          import(
            /* webpackChunkName: "payments" */ "../views/Payments/Wrapper.vue"
            ),
        children: [
          {
            path: "",
            name: "dash.payments.list",
            meta: {
              auth: true,
              can: permissions.CAN_VIEW_PAYMENTS,
              title: "Payments",
              route_identifier: 'payments'
            },
            component: () =>
              import(
                /* webpackChunkName: "payments" */ "../views/Payments/List.vue"
                )
          },
          {
            path: "create",
            name: "dash.payments.create",
            meta: {
              auth: true,
              can: permissions.CAN_MANAGE_PAYMENTS,
              title: "Add Payment",
              route_identifier: 'payments'
            },
            component: () =>
              import(
                /* webpackChunkName: "payments" */ "../views/Payments/Upsert.vue"
                )
          },
          {
            path: "view/:id",
            name: "dash.payments.view",
            meta: {
              auth: true,
              can: permissions.CAN_MANAGE_PAYMENTS,
              title: "View Payment",
              route_identifier: 'payments'
            },
            component: () =>
              import(
                /* webpackChunkName: "payments" */ "../views/Payments/View.vue"
                )
          },
          {
            path: "unpaid",
            name: "dash.payments.unpaid",
            meta: {
              auth: true,
              can: permissions.CAN_VIEW_PAYMENTS,
              title: "Due Payments",
              route_identifier: 'payments'
            },
            component: () =>
              import(
                /* webpackChunkName: "payments" */ "../views/Payments/Unpaid.vue"
                )
          },
          {
            path: ":id",
            name: "dash.payments.update",
            meta: {
              auth: true,
              can: permissions.CAN_MANAGE_PAYMENTS,
              title: "Update Payment",
              route_identifier: 'payments'
            },
            component: () =>
              import(
                /* webpackChunkName: "payments" */ "../views/Payments/Upsert.vue"
                )
          },
          {
            path: "refunds/:id",
            name: "dash.payments.refund",
            meta: {
              auth: true,
              can: permissions.CAN_MANAGE_PAYMENTS,
              title: "Create Refund",
              route_identifier: 'payments'
            },
            component: () =>
              import(
                /* webpackChunkName: "payments" */ "../views/Payments/RefundUpsert.vue"
                )
          }
        ]
      },
      // Reports
      {
        path: "reports",
        component: () =>
          import("../views/Reports/Wrapper.vue"),
        children: [
          {
            path: '',
            name: 'dash.reports',
            meta: {
              auth: true,
              can: permissions.CAN_VIEW_ORDERS,
              title: 'Reports',
              route_identifier: 'reports'
            },
            component: () => import('../views/Reports/Reports.vue')
          }
        ]
      },
      // Calendar
      {
        path: "calendars",
        component: () =>
          import(
            /* webpackChunkName: "fittings" */ "../views/Calendars/Wrapper.vue"
            ),
        children: [
          {
            path: '',
            name: 'dash.calendars',
            meta: {
              auth: true,
              can: permissions.CAN_VIEW_CALENDAR,
              title: 'Calendar',
              route_identifier: 'calendars'
            },
            component: () => import(/* webpackChunkName: "calendars" */ '../views/Calendars/Calendar.vue')
          }
        ]
      },
      // ToDos
      {
        path: "todos",
        component: () => import (
          /* webpackChunkName: "todos" */ "../views/Todos/Wrapper.vue"
          ),
        children: [
          {
            path: '',
            name: 'dash.todos.completed',
            meta: {
              auth: true,
              title: 'Completed ToDos',
              route_identifier: 'todos'
            },
            component: () => import(/* webpackChunkName: "todos" */ '../views/Todos/List.vue')
          }
        ]
      }
    ]
  },

  // Auth
  {
    path: "/auth",
    component: () =>
      import(
        /* webpackChunkName: "auth" */ "../views/Authentication/Container.vue"
        ),
    children: [
      {
        path: "not-allowed",
        name: "auth.not-allowed",
        meta: {
          auth: true,
          title: "Not Allowed"
        },
        component: () =>
          import(
            /* webpackChunkName: "auth" */ "../views/Authentication/NotAllowed.vue"
            )
      },
      {
        path: "login",
        name: "auth.login",
        meta: {
          auth: false,
          title: "Log In"
        },
        component: () =>
          import(
            /* webpackChunkName: "auth" */ "../views/Authentication/Login.vue"
            )
      },
      {
        path: "logout",
        name: "auth.logout",
        meta: {
          auth: true,
          title: "Log Out"
        },
        component: () =>
          import(
            /* webpackChunkName: "auth" */ "../views/Authentication/Logout.vue"
            )
      }
    ]
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

const permsKey = "authentication/userPermissions";
router.beforeEach((to, from, next) => {
  var passwordProtectedOrderRoutes = ['dash.orders.view', 'dash.orders.update'];
  if (passwordProtectedOrderRoutes.includes(to.name)) {
    window.axios.post(`orders/authenticate/${to.params.id}`, {
      password: to.params.pass
    }).then(response => {
      if (!response.data) return next({name: 'dash.orders.list'})
    }).catch(e => {
      return next({name: 'dash.orders.list'})
    })
  }

  /**
   * Clear Validation Errors.
   */
  Object.keys(store.state).forEach(module => {
    if (!["authentication", "dashboard"].includes(module)) {
      store.dispatch(`${module}/clearErrors`);
    }
  });

  /**
   * Authentication
   */

  if (to.matched.some(r => r.meta.auth)) {
    if (from.matched.some(r => r.meta.auth)) {
      store.dispatch('supply-orders/getNotificationCount')
      store.dispatch('fittings/getNotificationCount')
      store.dispatch('orders/getNoteNotificationCount')
      return next();
    }

    store
      .dispatch("authentication/check")
      .then(response => {
        if (to.matched.some(r => r.meta.can)) {
          if (includes(store.getters[permsKey], to.meta.can)) {
            store.dispatch('supply-orders/getNotificationCount')
            store.dispatch('fittings/getNotificationCount')
            store.dispatch('orders/getNoteNotificationCount')
            return next();
          }
          return next({
            name: "auth.not-allowed"
          });
        }
        return next();
      })
      .catch(() =>
        next({
          name: "auth.login",
          query: {
            next: from.path
          }
        })
      );
  } else if (to.matched.some(r => !r.meta.auth)) {
    if (from.matched.some(r => !r.meta.auth)) {
      return next();
    } else {
      store
        .dispatch("authentication/check")
        .then(() =>
          next({
            name: "dash.index"
          })
        )
        .catch(() => next());
    }
  } else {
    next();
  }
});

export default router;
