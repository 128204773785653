import Vue from "vue";
import {BootstrapVue, BootstrapVueIcons} from "bootstrap-vue";
import axios from "axios";
import {flare} from "@flareapp/flare-client";
import {flareVue} from "@flareapp/flare-vue";

import App from "./App";
import router from "./router";
import store from "./store";
import {library} from "@fortawesome/fontawesome-svg-core";
import {
  faAddressCard,
  faAngleLeft,
  faAngleRight,
  faArrowRight,
  faBellSlash,
  faColumns,
  faFileInvoice,
  faFileInvoiceDollar,
  faFlag,
  faHandSparkles,
  faHotel,
  faKey,
  faPen,
  faPlus,
  faPumpSoap,
  faRedo,
  faShippingFast,
  faStopwatch,
  faTasks,
  faTimes,
  faTrash,
  faUmbrellaBeach,
  faUpload,
  faUserPlus,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

flare.light('vMkwfpVY7nAkwEoHvJxgQ6kdBNUjTuwV');


Vue.use(BootstrapVueIcons);
Vue.use(BootstrapVue);
Vue.use(flareVue);


library.add(faAddressCard);
library.add(faAngleLeft);
library.add(faAngleRight);
library.add(faArrowRight);
library.add(faColumns);
library.add(faHandSparkles);
library.add(faHotel);
library.add(faFileInvoice);
library.add(faFileInvoiceDollar);
library.add(faKey);
library.add(faRedo);
library.add(faPen);
library.add(faPlus);
library.add(faPumpSoap);
library.add(faShippingFast);
library.add(faStopwatch);
library.add(faTasks);
library.add(faTrash);
library.add(faUmbrellaBeach);
library.add(faUpload);
library.add(faUsers);
library.add(faUserPlus);
library.add(faTimes);
library.add(faBellSlash);
library.add(faFlag);

Vue.component("fa-icon", FontAwesomeIcon);

window.axios = axios;
window.axios.defaults.baseURL = process.env.VUE_APP_API_URL;
window.axios.defaults.headers.Accept = "application/json";
window.axios.defaults.headers["X-Requested-With"] = "XMLHttpRequest";
window.axios.defaults.withCredentials = true;

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
