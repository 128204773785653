export const ORDERS_BATCH_DELETE_REQUEST = 'ORDERS_BATCH_DELETE_REQUEST'
export const ORDERS_BATCH_DELETE_SUCCESS = 'ORDERS_BATCH_DELETE_SUCCESS'
export const ORDERS_BATCH_DELETE_FAILURE = 'ORDERS_BATCH_DELETE_FAILURE'

export const ORDERS_FETCH_MANY_REQUEST = 'ORDERS_FETCH_MANY_REQUEST'
export const ORDERS_FETCH_MANY_SUCCESS = 'ORDERS_FETCH_MANY_SUCCESS'
export const ORDERS_FETCH_MANY_FAILURE = 'ORDERS_FETCH_MANY_FAILURE'

export const ORDERS_FETCH_REQUEST = 'ORDERS_FETCH_REQUEST'
export const ORDERS_FETCH_SUCCESS = 'ORDERS_FETCH_SUCCESS'
export const ORDERS_FETCH_FAILURE = 'ORDERS_FETCH_FAILURE'

export const ORDERS_SAVE_REQUEST = 'ORDERS_SAVE_REQUEST'
export const ORDERS_SAVE_SUCCESS = 'ORDERS_SAVE_SUCCESS'
export const ORDERS_SAVE_FAILURE = 'ORDERS_SAVE_FAILURE'

export const ORDERS_CANCEL_REQUEST = 'ORDERS_CANCEL_REQUEST'
export const ORDERS_CANCEL_SUCCESS = 'ORDERS_CANCEL_SUCCESS'
export const ORDERS_CANCEL_FAILURE = 'ORDERS_CANCEL_FAILURE'

export const EMPTY_ORDERS = 'EMPTY_ORDERS'
export const CLEAR_ERRORS = 'CLEAR_ERRORS'

export const ORDER_NOTIFICATION_COUNT_REQUEST = 'ORDER_NOTIFICATION_COUNT_REQUEST'
export const ORDER_NOTIFICATION_COUNT_SUCCESS = 'ORDER_NOTIFICATION_COUNT_SUCCESS'
export const ORDER_NOTIFICATION_COUNT_FAILURE = 'ORDER_NOTIFICATION_COUNT_FAILURE'