import pagination from "../../utils/pagination";
import {
    FITTINGS_BATCH_DELETE_REQUEST,
    FITTINGS_BATCH_DELETE_SUCCESS,
    FITTINGS_BATCH_DELETE_FAILURE,
    FITTINGS_FETCH_MANY_REQUEST,
    FITTINGS_FETCH_MANY_SUCCESS,
    FITTINGS_FETCH_MANY_FAILURE,
    FITTINGS_FETCH_UNDISMISSED_REQUEST,
    FITTINGS_FETCH_UNDISMISSED_SUCCESS,
    FITTINGS_FETCH_UNDISMISSED_FAILURE,
    FITTINGS_NOTIFICATION_COUNT_REQUEST,
    FITTINGS_NOTIFICATION_COUNT_SUCCESS,
    FITTINGS_NOTIFICATION_COUNT_FAILURE,
    FITTINGS_FETCH_REQUEST,
    FITTINGS_FETCH_SUCCESS,
    FITTINGS_FETCH_FAILURE,
    FITTINGS_SAVE_REQUEST,
    FITTINGS_SAVE_SUCCESS,
    FITTINGS_SAVE_FAILURE,
    EMPTY_FITTINGS,
    CLEAR_ERRORS
} from "./types";

export default {
    [FITTINGS_BATCH_DELETE_REQUEST](state) {
        state.deleting = true;
    },
    [FITTINGS_BATCH_DELETE_SUCCESS](state) {
        state.deleting = false;
    },
    [FITTINGS_BATCH_DELETE_FAILURE](state) {
        state.deleting = false;
    },
    [FITTINGS_FETCH_MANY_REQUEST](state) {
        state.data = [];
        state.loading = true;
    },
    [FITTINGS_FETCH_MANY_SUCCESS](state, { data }) {
        state.data = data.data;
        state.page = pagination(data);
        state.loading = false;
    },
    [FITTINGS_FETCH_MANY_FAILURE](state, { validationErrors }) {
        state.validationErrors = validationErrors || {};
        state.loading = false;
    },
    [FITTINGS_FETCH_UNDISMISSED_REQUEST](state) {
        state.data = [];
        state.loading = true;
    },
    [FITTINGS_FETCH_UNDISMISSED_SUCCESS](state, { data }) {
        state.data = data.data;
        state.page = pagination(data);
        state.loading = false;
    },
    [FITTINGS_FETCH_UNDISMISSED_FAILURE](state, { validationErrors }) {
        state.validationErrors = validationErrors || {};
        state.loading = false;
    },
    [FITTINGS_NOTIFICATION_COUNT_REQUEST] (state) {
  
    },
    [FITTINGS_NOTIFICATION_COUNT_SUCCESS] (state, { data }) {
      state.notificationCount = data
    },
    [FITTINGS_NOTIFICATION_COUNT_FAILURE] (state, { data }) {
      state.notificationCount = 0
    },
    [FITTINGS_FETCH_REQUEST](state) {
        state.single = {};
        state.loading = true;
    },
    [FITTINGS_FETCH_SUCCESS](state, { data }) {
        state.single = data;
        state.loading = false;
    },
    [FITTINGS_FETCH_FAILURE](state) {
        state.loading = false;
    },
    [FITTINGS_SAVE_REQUEST](state) {
        state.data = [];
        state.saving = true;
    },
    [FITTINGS_SAVE_SUCCESS](state) {
        state.saving = false;
    },
    [FITTINGS_SAVE_FAILURE](state, { validationErrors }) {
        state.validationErrors = validationErrors || {};
        state.saving = false;
    },
    [EMPTY_FITTINGS](state) {
        state.data = [];
        state.loading = false;
        state.saving = false;
    },
    [CLEAR_ERRORS](state) {
        state.validationErrors = [];
        state.loading = false;
        state.saving = false;
    }
};
