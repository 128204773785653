import {
    FITTINGS_BATCH_DELETE_REQUEST,
    FITTINGS_BATCH_DELETE_SUCCESS,
    FITTINGS_BATCH_DELETE_FAILURE,
    FITTINGS_FETCH_MANY_REQUEST,
    FITTINGS_FETCH_MANY_SUCCESS,
    FITTINGS_FETCH_MANY_FAILURE,
    FITTINGS_FETCH_UNDISMISSED_REQUEST,
    FITTINGS_FETCH_UNDISMISSED_SUCCESS,
    FITTINGS_FETCH_UNDISMISSED_FAILURE,
    FITTINGS_NOTIFICATION_COUNT_REQUEST,
    FITTINGS_NOTIFICATION_COUNT_SUCCESS,
    FITTINGS_NOTIFICATION_COUNT_FAILURE,
    FITTINGS_FETCH_REQUEST,
    FITTINGS_FETCH_SUCCESS,
    FITTINGS_FETCH_FAILURE,
    FITTINGS_SAVE_REQUEST,
    FITTINGS_SAVE_SUCCESS,
    FITTINGS_SAVE_FAILURE,
    EMPTY_FITTINGS,
    CLEAR_ERRORS
} from "./types";

export default {
    deleteMany({ commit }, { ids = [] }) {
        commit(FITTINGS_BATCH_DELETE_REQUEST);
        return window.axios
            .post("/fittings", { _method: "DELETE", ids })
            .then(response => {
                commit(FITTINGS_BATCH_DELETE_SUCCESS, response);
                return response;
            })
            .catch(error => {
                commit(FITTINGS_BATCH_DELETE_FAILURE, error);
                throw error;
            });
    },
    fetchMany(
        { commit },
        { query = "", page = 1, startDate = null, endDate = null, paymentStatus = null }
    ) {
        commit(FITTINGS_FETCH_MANY_REQUEST);
        return window.axios
            .get("/fittings", {
                params: {
                    query,
                    page,
                    startDate,
                    endDate,
                    paymentStatus,
                }
            })
            .then(response => {
                commit(FITTINGS_FETCH_MANY_SUCCESS, response);
                return response;
            })
            .catch(error => {
                commit(FITTINGS_FETCH_MANY_FAILURE, error);
                throw error;
            });
    },
    fetchFittingNotifications({ commit }, { query = "", page = 1 }) {
        commit(FITTINGS_FETCH_UNDISMISSED_REQUEST);
        return window.axios
            .get("/fittings/notifications", { params: { query, page } })
            .then(response => {
                commit(FITTINGS_FETCH_UNDISMISSED_SUCCESS, response);
                return response;
            })
            .catch(error => {
                commit(FITTINGS_FETCH_UNDISMISSED_FAILURE, error);
                throw error;
            });
    },
    dismissNotification ({ commit }, notificationId) {
        commit(FITTINGS_SAVE_REQUEST);
        return window.axios.post(`/fittings/notifications/dismiss/${notificationId}`)
            .then(response => {
                commit(FITTINGS_SAVE_SUCCESS, response);
                return response;
            })
            .catch(error => {
                if (error.response.status === 422) {
                    commit(FITTINGS_SAVE_FAILURE, {
                        validationErrors: error.response.data
                    });
                } else {
                    commit(FITTINGS_SAVE_FAILURE, { error });
                }
                throw error;
            });
    },
    getNotificationCount({ commit }) {
        commit(FITTINGS_NOTIFICATION_COUNT_REQUEST);
        return window.axios
            .get(`/fittings/notifications/count`)
            .then(response => {
                commit(FITTINGS_NOTIFICATION_COUNT_SUCCESS, response);
                return response;
            })
            .catch(error => {
                commit(FITTINGS_NOTIFICATION_COUNT_FAILURE);
            })
    },
    fetch({ commit }, id) {
        commit(FITTINGS_FETCH_REQUEST);
        return window.axios
            .get(`/fittings/${id}`)
            .then(response => {
                commit(FITTINGS_FETCH_SUCCESS, response);
                return response;
            })
            .catch(error => {
                commit(FITTINGS_FETCH_FAILURE, error);
                throw error;
            });
    },
    save({ commit }, order) {
        const path = order.id ? `/fittings/${order.id}` : "/fittings";
        const method = order.id ? "put" : "post";

        commit(FITTINGS_SAVE_REQUEST);
        return window.axios[method](path, order)
            .then(response => {
                commit(FITTINGS_SAVE_SUCCESS, response);
                return response;
            })
            .catch(error => {
                if (error.response.status === 422) {
                    commit(FITTINGS_SAVE_FAILURE, {
                        validationErrors: error.response.data
                    });
                } else {
                    commit(FITTINGS_SAVE_FAILURE, { error });
                }
                throw error;
            });
    },
    empty({ commit }) {
        commit(EMPTY_FITTINGS);
    },
    clearErrors({ commit }) {
        commit(CLEAR_ERRORS);
    }
};
