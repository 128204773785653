<template>
  <div id="app">
    <CardNotifications>
      <transition name="fade" mode="out-in">
        <div class="align-items-center bg-primary d-flex flex-column justify-content-center vh-100" v-if="isLoading && !loaded">
          <b-spinner variant="light"></b-spinner>
        </div>
        <router-view v-if="!isLoading || loaded" />
      </transition>
    </CardNotifications>
  </div>
</template>

<script>

import CardNotifications from './components/CardNotifications'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    CardNotifications
  },
  created () {
    this.token()
  },
  data () {
    return {
      loaded: false
    }
  },
  computed: {
    ...mapGetters('authentication', ['isLoading', 'xsrfTokenLoaded'])
  },
  methods: {
    ...mapActions('authentication', ['token'])
  },
  watch: {
    '$route' (val) {
      document.title = val.meta.title ? `${val.meta.title} | North East Carpets` : 'North East Carpets'
    },
    isLoading (val) {
      if (val) this.loaded = true
    }
  }
}
</script>

<style lang="scss">
  $link-color: $secondary;
  @import "bootstrap";
  @import "~bootstrap-vue/dist/bootstrap-vue.css";

  body {
    background: lighten($light, 2.5%);
  }

  .back-button {
    margin-left: -100%;
    position: relative;
  }

  .modal-backdrop {
    background: $primary;
    opacity: 0.8;
  }

  .fade-enter-active, .fade-leave-active {
    transition: opacity 250ms;
  }
  .fade-enter, .fade-leave-to {
    opacity: 0;
  }

@media print {
  .content {
    max-width: 100%;
    width: 100%;
    margin-left: 0 !important;
    flex: 100% !important;
  }
}

</style>
