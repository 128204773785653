export const FITTINGS_BATCH_DELETE_REQUEST = "FITTINGS_BATCH_DELETE_REQUEST";
export const FITTINGS_BATCH_DELETE_SUCCESS = "FITTINGS_BATCH_DELETE_SUCCESS";
export const FITTINGS_BATCH_DELETE_FAILURE = "FITTINGS_BATCH_DELETE_FAILURE";

export const FITTINGS_FETCH_MANY_REQUEST = "FITTINGS_FETCH_MANY_REQUEST";
export const FITTINGS_FETCH_MANY_SUCCESS = "FITTINGS_FETCH_MANY_SUCCESS";
export const FITTINGS_FETCH_MANY_FAILURE = "FITTINGS_FETCH_MANY_FAILURE";

export const FITTINGS_FETCH_UNDISMISSED_REQUEST = "FITTINGS_FETCH_UNDISMISSED_REQUEST";
export const FITTINGS_FETCH_UNDISMISSED_SUCCESS = "FITTINGS_FETCH_UNDISMISSED_SUCCESS";
export const FITTINGS_FETCH_UNDISMISSED_FAILURE = "FITTINGS_FETCH_UNDISMISSED_FAILURE";

export const FITTINGS_NOTIFICATION_COUNT_REQUEST = "FITTINGS_NOTIFICATION_COUNT_REQUEST";
export const FITTINGS_NOTIFICATION_COUNT_SUCCESS = "FITTINGS_NOTIFICATION_COUNT_SUCCESS";
export const FITTINGS_NOTIFICATION_COUNT_FAILURE = "FITTINGS_NOTIFICATION_COUNT_FAILURE";

export const FITTINGS_FETCH_REQUEST = "FITTINGS_FETCH_REQUEST";
export const FITTINGS_FETCH_SUCCESS = "FITTINGS_FETCH_SUCCESS";
export const FITTINGS_FETCH_FAILURE = "FITTINGS_FETCH_FAILURE";

export const FITTINGS_SAVE_REQUEST = "FITTINGS_SAVE_REQUEST";
export const FITTINGS_SAVE_SUCCESS = "FITTINGS_SAVE_SUCCESS";
export const FITTINGS_SAVE_FAILURE = "FITTINGS_SAVE_FAILURE";

export const EMPTY_FITTINGS = "EMPTY_FITTINGS";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
