export default {
    currentUser ({ current }) {
      return current.user || {}
    },
    isLoading ({ login, logout, xsrf }) {
      return login.loading || logout.loading || xsrf.loading
    },
    isLoggingIn ({ login }) {
      return login.loading
    },
    loginErrors ({ login }) {
      return login.errors || {}
    },
    logoutError ({ logout }) {
      return logout.errors || {}
    },
    userPermissions ({ current }) {
      return current.perms || []
    },
    hasAnyPermission: ({ current }) => (permissions) => {
      let hasAnyPermission = false
			if (Array.isArray(permissions)) {
				permissions.forEach(function (permission) {
					if (current.perms.includes(permission)) hasAnyPermission = true
				})
			}
			return hasAnyPermission
    },

    xsrfTokenLoaded ({ xsrf }) {
      return xsrf.token
    },
  }
