import pagination from '../../utils/pagination'
import {
  CLEAR_ERRORS,
  EMPTY_ORDERS,
  ORDER_NOTIFICATION_COUNT_FAILURE,
  ORDER_NOTIFICATION_COUNT_REQUEST,
  ORDER_NOTIFICATION_COUNT_SUCCESS,
  ORDERS_BATCH_DELETE_FAILURE,
  ORDERS_BATCH_DELETE_REQUEST,
  ORDERS_BATCH_DELETE_SUCCESS,
  ORDERS_CANCEL_FAILURE,
  ORDERS_CANCEL_REQUEST,
  ORDERS_CANCEL_SUCCESS,
  ORDERS_FETCH_FAILURE,
  ORDERS_FETCH_MANY_FAILURE,
  ORDERS_FETCH_MANY_REQUEST,
  ORDERS_FETCH_MANY_SUCCESS,
  ORDERS_FETCH_REQUEST,
  ORDERS_FETCH_SUCCESS,
  ORDERS_SAVE_FAILURE,
  ORDERS_SAVE_REQUEST,
  ORDERS_SAVE_SUCCESS
} from './types'

export default {
  [ORDERS_BATCH_DELETE_REQUEST](state) {
    state.deleting = true
  },
  [ORDERS_BATCH_DELETE_SUCCESS](state) {
    state.deleting = false
  },
  [ORDERS_BATCH_DELETE_FAILURE](state) {
    state.deleting = false
  },
  [ORDERS_FETCH_MANY_REQUEST](state) {
    state.data = []
    state.loading = true
  },
  [ORDERS_FETCH_MANY_SUCCESS](state, {data}) {
    state.data = data.data
    state.page = pagination(data)
    state.loading = false
  },
  [ORDERS_FETCH_MANY_FAILURE](state, {validationErrors}) {
    state.validationErrors = validationErrors || {}
    state.loading = false
  },
  [ORDERS_FETCH_REQUEST](state) {
    state.single = {}
    state.loading = true
  },
  [ORDERS_FETCH_SUCCESS](state, {data}) {
    state.single = data
    state.loading = false
  },
  [ORDERS_FETCH_FAILURE](state) {
    state.loading = false
  },
  [ORDERS_SAVE_REQUEST](state) {
    state.data = []
    state.saving = true
  },
  [ORDERS_SAVE_SUCCESS](state) {
    state.saving = false
  },
  [ORDERS_SAVE_FAILURE](state, {validationErrors}) {
    state.validationErrors = validationErrors || {}
    state.saving = false
  },
  [ORDERS_CANCEL_REQUEST](state) {
    state.single = {}
    state.loading = true
  },
  [ORDERS_CANCEL_SUCCESS](state, {data}) {
    state.single = data
    state.loading = false
  },
  [ORDERS_CANCEL_FAILURE](state, {validationErrors}) {
    state.validationErrors = validationErrors || {}
    state.loading = false
  },
  [EMPTY_ORDERS](state) {
    state.data = []
    state.loading = false
    state.saving = false
  },
  [CLEAR_ERRORS](state) {
    state.validationErrors = []
    state.loading = false
    state.saving = false
  },
  [ORDER_NOTIFICATION_COUNT_REQUEST](state) {

  },
  [ORDER_NOTIFICATION_COUNT_SUCCESS](state, {data}) {
    state.notificationNoteCount = data
  },
  [ORDER_NOTIFICATION_COUNT_FAILURE](state, {data}) {
    state.notificationNoteCount = 0
  },
}
