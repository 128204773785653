import Vue from "vue";
import Vuex from "vuex";
import authentication from "./authentication";
import supplyOrders from "./supply-orders";
import fittings from "./fittings";
import orders from "./orders";
import todos from "./todos";

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        authentication,
        fittings,
        orders,
        'supply-orders': supplyOrders,
        todos
    }
});
