<template>
  <div class="notifs">
    <slot></slot>
    <div class="d-none d-md-flex flex-column justify-content-end notifs-area p-4 vh-100">
      <transition-group name="fade" mode="out-in">
        <b-card v-for="(notification, $index) in sortedNotifications" :class="`border-${notification.variant || 'dark'} ${notification.dark ? 'bg-dark text-white' : ''}`" class="mt-4 shadow" :key="`notif-${$index}`" :sub-title="notification.subtitle" :title="notification.title">
          <div class="d-flex flex-row">
            <div v-for="(button, $buttonIndex) in notification.buttonLayout" :class="{'mr-1': notification.buttonLayout.length - 1 !== $buttonIndex, 'flex-fill': !Array.isArray(button)}" :key="`notif-${index}-button-${$buttonIndex}`">
              <b-button block :variant="button.variant" v-if="!Array.isArray(button)" v-b-popover.hover.top="button.popover"><b-icon :class="{'mr-2': button.text}" :icon="button.icon" v-if="button.icon"></b-icon>{{ button.text }}</b-button>
              <div class="d-flex flex-row" v-if="Array.isArray(button)">
                <div v-for="(button, $buttonIndex) in button" :class="{'mr-1': notification.buttonLayout.length - 1 !== $buttonIndex, 'flex-fill': !Array.isArray(button)}" :key="`notif-${index}-button-${$buttonIndex}`">
                  <b-button block :variant="button.variant" v-if="!Array.isArray(button)" v-b-popover.hover.top="button.popover"><b-icon :class="{'mr-2': button.text}" :icon="button.icon" v-if="button.icon"></b-icon>{{ button.text }}</b-button>
                </div>
              </div>
            </div>
          </div>
          <b-card-text v-if="notification.text">{{ notification.text }}</b-card-text>
        </b-card>
      </transition-group>
    </div>
  </div>
</template>

<script>
import orderBy from 'lodash/orderBy'

export default {
  computed: {
    sortedNotifications () {
      return orderBy(this.notifications, 'ts', 'desc')
    }
  },
  data () {
    return {
      notifications: []
    }
  },
  mounted() {
    this.$root.$on('card-notification', notification => {
      this.notifications.push(notification)
      setTimeout(() => {
        this.notifications.splice(this.notifications.indexOf(notification), 1)
      }, notification.timeout ?? 2500)
    })
  },
  watch: {
    '$route': function(to, from) {
      (to.params.notifications ?? []).forEach((notification, index) => {
        this.$root.$emit('card-notification', notification)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .notifs {
    .notifs-area {
      position: fixed;
      top: 0; right: 0;
      pointer-events: none;
      max-width: 500px;
      z-index: 998 !important;
      width: 100%;
    }

    .card {
      pointer-events: initial;
      position: relative;

      &.bg-dark {
        h6.card-subtitle.text-muted.mb-2 {
          color: darken($light, 15%) !important;
        }
      }

      .close {
        cursor: pointer;
        position: absolute;
        top: .5rem; right: .5rem;
      }
    }
  }
</style>
