export default {
  data ({ data }) {
    return data
  },
  notificationCount ({ notificationCount }) {
    return notificationCount
  },
  errors ({ validationErrors }) {
    return validationErrors
  },
  isDeleting ({ deleting }) {
    return deleting
  },
  isLoading ({ loading }) {
    return loading
  },
  isSaving ({ saving }) {
    return saving
  },
  pagination ({ page }) {
    return page
  },
  single ({ single }) {
    return single
  }
}
