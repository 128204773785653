<template>
  <div>
    <b-row class="mt-4">
      <b-col v-if="!todoList.length" cols="12">
        <b-row cols="12" md="6">
          <b-col class="text-center" cols="12">
            <b-card class="py-3 text-center">
              <p class="mb-4">
                <fa-icon class="display-1 text-dark" icon="times"></fa-icon>
              </p>
              <p class="font-weight-normal h5 mb-0 text-dark">There are no items.</p>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
      <b-col v-if="todoList.length" cols="12">
        <b-list-group class="overflow-auto">
          <b-list-group-item v-for="(todo, todoInd) in todoList" :key="`todo${todoInd}`"
                             role="button" @click="setTodoEditingIndex(todoInd)">
            <b-row>
              <b-col class="d-flex align-items-center" cols="10">
                <div v-show="editingTodoIndex === todoInd" class="w-100">
                  <b-form-input v-if="!todoSaving" :ref="'todoInput' + todoInd" v-model="todo.todo"
                                :disabled="todoSaving" placeholder="Enter a new item..."
                                @blur="doSaveTodo(todo, todoInd)"></b-form-input>
                  <b-spinner v-if="todoSaving" variant="primary">Saving...</b-spinner>
                </div>
                <div v-show="editingTodoIndex !== todoInd" class="flex-fill" role="button">
                  <p class="mb-0">{{ todo.todo }}</p>
                </div>
              </b-col>
              <b-col class="d-flex align-items-center justify-content-end" cols="2">
                <b-button variant="primary" @click="doComplete(todo)">Mark Complete</b-button>
              </b-col>
            </b-row>
          </b-list-group-item>
        </b-list-group>
      </b-col>
      <b-col class="d-flex mt-4 align-items-center justify-content-start" cols="12">
        <b-button variant="primary" @click="addTodo">Add Item</b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex"
import currentUser from "../mixins/currentUser"
import momentMixin from "../mixins/momentMixin"

export default {
  created() {
    this.fetchManyTodos({})
  },
  mixins: [currentUser, momentMixin],
  computed: {
    ...mapGetters("todos", {
      todoList: "data",
      todosLoading: "isLoading",
      todoSaving: "isSaving",
    })
  },
  data() {
    return {
      editingTodoIndex: null,
      dateFrom: null,
      dateTo: null,
      completed: null
    }
  },

  methods: {
    ...mapActions("todos", {
      saveTodo: "save",
      fetchManyTodos: "fetchMany"
    }),

    setTodoEditingIndex(todoInd) {
      this.editingTodoIndex = todoInd
    },

    addTodo() {
      this.todoList.push({
        id: null,
        todo: null,
        completed: null,
        user_id: this.currentUser.id,
        user: this.currentUser
      })

      this.editingTodoIndex = this.todoList.length - 1
    },

    doSaveTodo(todo, todoInd) {
      if (todo.todo == null || todo.todo == "") {
        this.todoList.splice(todoInd, 1)
      } else {
        this.saveTodo(todo).then(() => {
          this.editingTodoIndex = null
        })
      }
    },

    doComplete(todo) {
      todo.completed = this.moment().format("DD/MM/YYYY HH:mm:ss")
      this.doSaveTodo(todo)
    }
  }
}
</script>

<style lang="scss" scoped>
.list-group-item {
  border-bottom: 1px solid rgba(0, 0, 0, 0.125) !important;
}
</style>
