import {
  CLEAR_ERRORS,
  EMPTY_TODOS,
  TODOS_BATCH_DELETE_FAILURE,
  TODOS_BATCH_DELETE_REQUEST,
  TODOS_BATCH_DELETE_SUCCESS,
  TODOS_FETCH_FAILURE,
  TODOS_FETCH_MANY_COMPLETED_FAILURE,
  TODOS_FETCH_MANY_COMPLETED_REQUEST,
  TODOS_FETCH_MANY_COMPLETED_SUCCESS,
  TODOS_FETCH_MANY_FAILURE,
  TODOS_FETCH_MANY_REQUEST,
  TODOS_FETCH_MANY_SUCCESS,
  TODOS_FETCH_REQUEST,
  TODOS_FETCH_SUCCESS,
  TODOS_SAVE_FAILURE,
  TODOS_SAVE_REQUEST,
  TODOS_SAVE_SUCCESS,
} from './types'

export default {
  [TODOS_FETCH_MANY_COMPLETED_REQUEST](state) {
    state.loading = true
  },
  [TODOS_FETCH_MANY_COMPLETED_SUCCESS](state, {data}) {
    state.dataCompleted = data
    state.loading = false
  },
  [TODOS_FETCH_MANY_COMPLETED_FAILURE](state, {validationErrors}) {
    state.validationErrors = validationErrors || {}
    state.loading = false
  },
  [TODOS_BATCH_DELETE_REQUEST](state) {
    state.deleting = true
  },
  [TODOS_BATCH_DELETE_SUCCESS](state) {
    state.deleting = false
  },
  [TODOS_BATCH_DELETE_FAILURE](state) {
    state.deleting = false
  },
  [TODOS_FETCH_MANY_REQUEST](state) {
    state.loading = true
  },
  [TODOS_FETCH_MANY_SUCCESS](state, {data}) {
    state.data = data
    state.loading = false
  },
  [TODOS_FETCH_MANY_FAILURE](state, {validationErrors}) {
    state.validationErrors = validationErrors || {}
    state.loading = false
  },
  [TODOS_FETCH_REQUEST](state) {
    state.single = {}
    state.loading = true
  },
  [TODOS_FETCH_SUCCESS](state, {data}) {
    state.single = data
    state.loading = false
  },
  [TODOS_FETCH_FAILURE](state) {
    state.loading = false
  },
  [TODOS_SAVE_REQUEST](state) {
    state.saving = true
  },
  [TODOS_SAVE_SUCCESS](state) {
    state.saving = false
  },
  [TODOS_SAVE_FAILURE](state, {validationErrors}) {
    state.validationErrors = validationErrors || {}
    state.saving = false
  },
  [EMPTY_TODOS](state) {
    state.data = []
    state.loading = false
    state.saving = false
  },
  [CLEAR_ERRORS](state) {
    state.validationErrors = []
    state.loading = false
    state.saving = false
  },
}
